import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectStatusEnum } from 'src/app/core/models/enums/select-status.enum';
import { SubTypeModuleEnum } from 'src/app/core/models/enums/subtype-module.enum';
import { StatusSelectViewModel } from 'src/app/core/models/interfaces/status-select.model';
import ModuleRequest from 'src/app/core/models/request/module-request.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { ModalNewStatusComponent } from '../modal-new-status/modal-new-status.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateScheduleRequest, ScheduleStatus } from 'src/app/core/models/request/schedules-request.model';
import { SchedulingService } from 'src/app/core/services/schedulingServices/scheduling.service';
import { SchedulePolicyStatusEnum } from 'src/app/core/models/enums/schedules-policy-status.enum';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.scss']
})

export class CreateScheduleComponent {

  modules:  ModuleRequest[] = [];
  status: StatusSelectViewModel[] = []
  select: string = 'register.users.select';
  enabled: boolean = false;
  hide: boolean = true;
  dialogRef!: MatDialogRef<any>;
  subType!: SubTypeModuleEnum;
  form: CreateScheduleRequest = {} as CreateScheduleRequest;
  schedulesStatus: ScheduleStatus[] = [];
  scheduleFormGroup: FormGroup;
  labelInfo01: string = "controlPanel.schedules.container.info.infoLabel01";
  labelInfo02: string = "controlPanel.schedules.container.info.infoLabel02";
  scheduleSunday: ScheduleStatus[] = [];
  scheduleMonday: ScheduleStatus[] = [];
  scheduleTuesday: ScheduleStatus[] = [];
  scheduleWednesday: ScheduleStatus[] = [];
  scheduleThursday: ScheduleStatus[] = [];
  scheduleFriday: ScheduleStatus[] = [];
  scheduleSaturday: ScheduleStatus[] = [];

  constructor(
    private translate: TranslateService, 
    private moduleService: ModuleService,
    private schedulingService: SchedulingService,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { 
    this.scheduleFormGroup = this.fb.group({
      moduleId: ['', [Validators.required]],
      statusDefault: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.getAllModules()
  }

  getAllModules(): void {
    this.moduleService.getAll().then((resp) => {
      this.modules = resp;
    })
  }

  get isValidForm(): boolean {
    return this.scheduleFormGroup.valid;
  }

  get isValidSubmit(): boolean {
    return this.scheduleFormGroup.valid && this.enabled
  }

  setOptionStatus(subType: SubTypeModuleEnum): void {
    this.subType = subType;
    switch (subType) {
      case SubTypeModuleEnum.dispatchable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy}
        ]
        break;

        case SubTypeModuleEnum.controllable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.statusLoadOff', statusType: SchedulePolicyStatusEnum.off}
        ]
        break;
    
      default:
        this.status = [
          {name: 'module.statusCharge', statusType: SchedulePolicyStatusEnum.charge}, 
          {name: 'module.statusUncharge', statusType: SchedulePolicyStatusEnum.uncharge},
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy},
        ]
        break;
    }
  }

  openDialogNewStatus(): void {
    this.dialogRef = this.dialog.open(ModalNewStatusComponent, {
      disableClose: true,
      data: { subType: this.subType}
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async (result: ScheduleStatus) => {
        this.schedulesStatus.push(result)

        if (result) {
          this.enabled = true;
        }
        
        this.form.moduleId = this.scheduleFormGroup.get(['moduleId'])?.value;
        this.form.statusDefault = this.scheduleFormGroup.get(['statusDefault'])?.value;
        this.form.schedulerStatus = this.schedulesStatus;
        
        this.setCardSchedule(result)
        this.dialogRef.close();
      },
    });
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }

  setCardSchedule(value: ScheduleStatus): void {
    value.days.forEach(x => {
      switch (x) {
        case -1:
          this.scheduleSunday.push(value)
          this.scheduleMonday.push(value)
          this.scheduleTuesday.push(value)
          this.scheduleWednesday.push(value)
          this.scheduleThursday.push(value)
          this.scheduleFriday.push(value)
          this.scheduleSaturday.push(value)
          this.form.schedulerStatus[0].days = [0, 1, 2, 3, 4, 5, 6]
        break;
        case 6:
          this.scheduleSunday.push(value)
        break;
        case 0:
          this.scheduleMonday.push(value)
        break;
        case 1:
          this.scheduleTuesday.push(value)
        break;
        case 2:
          this.scheduleWednesday.push(value)
        break;
        case 3:
          this.scheduleThursday.push(value)
        break;
        case 4:
          this.scheduleFriday.push(value)
        break;
        case 5:
          this.scheduleSaturday.push(value)
        break;
      
        default:
          break;
      }
    })
  }

  onSubmit(): void {
    this.schedulingService.createNewStatus(this.form).then((resp) => {
        this.openSnackBar(resp);
    })
  }

  openSnackBar(status: number): void {
    switch (status) {
          case AppConstants.CREATE_SUCESS:
            this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
              data: {
                message: this.translate.instant('controlPanel.schedules.container.info.createScheduleSuccess'),
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            });
            this.goTo('/control-panel/scheduling');
            break;
          case AppConstants.OK:
            this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
              data: {
                message: this.translate.instant('register.modal.updateUserSucess'),
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            });
            break;
          default:
            this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
              data: {
                message: this.translate.instant('controlPanel.schedules.container.info.errorCreateSchedule'),
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            });
            break;
        }
  }
}

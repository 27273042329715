<div class="create-schedules">
    <div class="create-schedules__header">
        <h2>{{ "controlPanel.schedules.createSchedule" | translate }}</h2>
        <p>{{ "controlPanel.schedules.createScheduleInfo" | translate }}</p>
    </div>

    <div class="create-schedules__container">
        <h3 class="create-schedules__container-header">
            {{ "controlPanel.schedules.container.recurringWeeklySchedule" | translate }}
        </h3>

        <div class="create-schedules__container-new">
            <form class="select" [formGroup]="scheduleFormGroup">
                <div>
                    <div class="label">
                        <label class="info" for="module">
                            {{ "controlPanel.schedules.container.energyResource" | translate }}
                        </label>
                        <i class="fa-solid fa-circle-info"
                            matTooltip="{{ labelInfo01 | translate }}"
                            matTooltipPosition="above"
                            matTooltipClass="scheule-label-tooltip">
                        </i>
                    </div>
                    <mat-form-field appearance="outline" class="select">
                        <mat-select placeholder="{{ select | translate}}" formControlName="moduleId">
                            <mat-option *ngFor="let module of modules" [value]="module.moduleId" class="module-options" >
                                <p class="option" (click)="setOptionStatus(module.subtype)">{{ module.name }}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <div class="label">
                        <label class="info" for="module">
                            {{ "controlPanel.schedules.container.defaultStatus" | translate }}
                        </label>
                        <i class="fa-solid fa-circle-info"
                            matTooltip="{{ labelInfo02 | translate }}"
                            matTooltipPosition="above"
                            matTooltipClass="scheule-label-tooltip">
                        </i>
                    </div>
                    <mat-form-field appearance="outline" class="select">
                        <mat-select placeholder="{{ select | translate}}" formControlName="statusDefault">
                            <mat-option *ngFor="let status of status" [value]="status.statusType" class="module-options">
                                <p class="option">{{ status.name | translate }}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            
            <button class="btn" [class.disabled]="!isValidForm" (click)="openDialogNewStatus()" [disabled]="!isValidForm">
                {{ "controlPanel.schedules.container.newStatus" | translate }}
            </button>

        </div>

        <div class="create-schedules__container-programming">
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Sunday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleSunday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleSunday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Monday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleMonday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleMonday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Tuesday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleTuesday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleTuesday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Wednesday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleWednesday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleWednesday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Thursday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleThursday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleThursday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Friday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleFriday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleFriday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
            <div class="day">
                <h3>{{ "controlPanel.schedules.container.days.Saturday" | translate }}</h3>
                <section>
                    <p *ngIf="scheduleSaturday.length == 0">{{ "controlPanel.schedules.container.info.notSchedule" | translate }}</p>
                    <app-schedule-card *ngFor="let day of scheduleSaturday"
                        [status]="day.status"
                        [endTime]="day.endTime"
                        [startTime]="day.startTime"
                    ></app-schedule-card>
                </section>
            </div>
        </div>

        <div class="create-schedules__container-info">
            <span>{{ "analysis.timeSeries.important" | translate}}:</span>
            <ul>
                <li>{{ "controlPanel.schedules.container.info.text01" | translate }}</li>
                <li>{{ "controlPanel.schedules.container.info.text02" | translate }}</li>
            </ul>
        </div>

        <hr>

        <div class="create-schedules__container-btn">
            <button class="btn cancel" (click)="goTo('/control-panel/scheduling')">
                {{ "controlPanel.schedules.container.cancel" | translate }}
            </button>
            <button class="btn" [class.disabled]="!isValidSubmit" (click)="onSubmit()">{{ "controlPanel.schedules.container.save" | translate }}</button>
        </div>
    </div>
</div>
import {
	AfterViewInit,
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { FormFieldBaseDirective } from '../../directive/form-field-base';
import { InputTime, RangeStr } from '../../interfaces/input-time.interface';
import { TimePickerService } from 'src/app/core/services/timePickerService/time-picker.service';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent extends FormFieldBaseDirective
implements AfterViewInit {

	toThe: string = "controlPanel.schedules.container.timePicker.toThe";
  	@Output() changes: EventEmitter<any> = new EventEmitter();

	@Input() isRange = false;
	@Input() haveSeconds = false;
	@Input() canOpen = true;

	@ViewChild('confirmButton') confirmButton!: TemplateRef<any>;
	@ViewChild('cancelButton') cancelButton!: TemplateRef<any>;
	@ViewChild('navmenu', { static: true }) navMenu!: MatMenu;

	@HostListener('window:resize', ['$event'])
	onResize() {
		const element = document.getElementById('timepicker-' + this.id);
		this.panelWidth = element?.getBoundingClientRect().width || 0;
	}
	panelWidth = 0;
	ctrlTime = new FormControl({ value: '', disabled: true });

	timeRange: { start: string; end: string } = { start: '00:00', end: '' };
	time = '';
	rangeError = false;

	@Input() set inputTime(time: string) {
		if (time || time != '') {
			this.time = time;
			this.ctrlTime.setValue(time);
		}
	}

	@Input() set rangeTime(time: string[]) {
		if (time && time.length > 0) {
			this.timeRange.start = time[0];
			this.timeRange.end = time[1];
			this.ctrlTime.setValue(time[0] + ' - ' + time[1]);
		}
	}

	get initRange(): { start: string; end: string } {
		return {
			start: this.haveSeconds ? '00:00:00' : '00:00',
			end: this.haveSeconds ? '00:00:00' : '00:00',
		};
	}

	get initSingle(): string {
		return this.haveSeconds ? '00:00:00' : '00:00';
	}

	constructor(private service: TimePickerService) {
		super();
		this.isRange = false;
		this.timeRange = this.initRange;
		this.time = this.initSingle;
	}

	ngAfterViewInit(): void {
		this.onResize();
	}

	onTimeChanges(time: InputTime) {
		this.time = this.service.formatTime(time, this.haveSeconds);
	}

	onRangeChanges(time: InputTime, initialOrLast: RangeStr) {
		if (initialOrLast == 'initial')
			this.timeRange.start = this.service.formatTime(time, this.haveSeconds);
		else this.timeRange.end = this.service.formatTime(time, this.haveSeconds);
	}

	apply(): void {
		this.setRangeError();
		if (this.rangeError) return;
		if (this.isRange) {
			this.ctrlTime.setValue(this.timeRange.start + ' - ' + this.timeRange.end);
			this.changes.emit(this.timeRange);
			return;
		}
		this.ctrlTime.setValue(this.time);
		this.changes.emit({ start: this.ctrlTime.value });
	}

	setRangeError(): void {
		if (
			!this.service.validateTimeRange(
				this.timeRange.start,
				this.timeRange.end
			) &&
			this.isRange
		) {
			this.rangeError = true;
			return;
		}
		this.rangeError = false;
	}

	clear() {
		this.timeRange = this.initRange;
		this.ctrlTime.setValue(null);
		this.changes.emit({ start: null });
	}

}

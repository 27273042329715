<div class="dialog-new-status">
    <h2 class="dialog-new-status__header">
        {{ "controlPanel.schedules.container.newStatus" | translate }}
    </h2>
    <form #statusForm="ngForm">
        <div class="dialog-new-status__status">
            <label class="info" for="module">
                {{ "controlPanel.schedules.container.modal.status" | translate }}
            </label>
            <mat-form-field appearance="outline" class="select">
                <mat-select placeholder="{{ select | translate}}" name="status" [(ngModel)]="form.status" required>
                    <mat-option *ngFor="let status of status" [value]="status.statusType" class="status-options">
                        <p class="option">{{ status.name | translate }}</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <div class="dialog-new-status__period">
            <div class="day">
                <label class="info" for="module">
                    {{ "controlPanel.schedules.container.modal.dayWeek" | translate }}
                </label>
                <mat-form-field appearance="outline" class="select">
                    <mat-select placeholder="{{ select | translate}}" multiple name="days" [(ngModel)]="form.days" required>
                        <mat-option *ngFor="let day of days" [value]="day.id" class="day-options">
                            <p class="option">{{ day.name | translate }}</p>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    
            <app-time-picker
                [title]="'controlPanel.schedules.container.modal.period' | translate"
                [placeholder]="'controlPanel.schedules.container.modal.period' | translate"
                [isRange]="true"
                (changes)="onTimeChanged($event)"
                [required]="true"
                [showError]="false"
                [canOpen]="true"
                [rangeTime]="timeRange"></app-time-picker>
        </div>

        <h3 class="dialog-new-status__info">
            <span>{{ "controlPanel.schedules.container.modal.attention" | translate }}: </span> {{ "controlPanel.schedules.container.modal.tex01" | translate }}
        </h3>
    
        <hr>
    
        <div class="dialog-new-status__btn">
            <button class="btn cancel" (click)="onDismiss()">
                {{ "controlPanel.schedules.container.cancel" | translate }}
            </button>
            <button class="btn" [class.disabled]="statusForm.form.invalid || !formValid" (click)="onConfirm()" [disabled]="!statusForm.form.valid">
                {{ "controlPanel.schedules.container.save" | translate }}
            </button>
        </div>
    </form>
</div>

<div class="new-energy-contracts">
    
    <app-new-energy-contracts-form *ngIf="editContractForm == true" (contractSaved)="newContractEvent($event)" ></app-new-energy-contracts-form>

    <div *ngIf="editContractForm == false" class="new-energy-contracts__container-register-rules-filter-keywords">
        <div class="new-energy-contracts__container__header">
            <h2>{{ "register.energyContracts.rulesFilterTitle1" | translate }}</h2>
            <p>{{ "register.energyContracts.rulesFilterInfo1" | translate }}</p>
        </div>
        <form action=""  class="new-energy-contracts__container-register-rules-filter-keywords__fields" [formGroup]="filterRulesForm">
            <div>
                <label for="keywords">{{ "register.energyContracts.keywords" | translate }}</label>
                <mat-form-field class="keywords-chip-list" appearance="outline">
                    <mat-chip-grid #chipGrid>
                      <mat-chip-row *ngFor="let keyword of keywords" (removed)="removeKeyword(keyword)">
                        {{keyword}}
                        <button matChipRemove [attr.aria-label]="'remove ' + keyword">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input placeholder='{{ "register.energyContracts.newKeyword" | translate }}' #keywordInput [formControl]="keywordCtrl"
                        [matChipInputFor]="chipGrid" [matAutocomplete]="auto" style="height: fit-content;"
                        (matChipInputTokenEnd)="addKeyword($event)"/>
                        <mat-label class="remove-keyword" (click)="clearKeywords()">X</mat-label>
                    </mat-chip-grid>
                    
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedKeywordEvent($event)">
                      <mat-option *ngFor="let keyword of filteredKeywords | async" [value]="keyword">
                        {{keyword}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
        </form>
    </div>
    <br>
    <div *ngIf="editContractForm == false" class="new-energy-contracts__container-list">
        <div class="new-energy-contracts__container__header">
            <h2>{{ "register.energyContracts.rulesFilterTitle2" | translate }}</h2>
            <p>{{ "register.energyContracts.rulesFilterInfo2" | translate }}</p>
        </div>

        <div class="list-rules-table">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="selected">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let rule"> 
                        <mat-checkbox [checked]="rule.selected" (change)="selectedRowRuleEvent(rule, $event)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-rules-column"> {{ "register.energyContracts.tableRule.name" | translate }} </th>
                    <td mat-cell *matCellDef="let rule" class="list-rules-column"> {{rule.name}} </td>
                </ng-container>
                <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-rules-column"> {{ "register.energyContracts.tableRule.keywords" | translate }} </th>
                    <td mat-cell *matCellDef="let rule" class="list-rules-column"> {{rule.tagsFormated}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row"></tr>

            </table>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                aria-label="Items por pagina" showFirstLastButtons class="border">
            </mat-paginator>
        </div>

        <div class="new-energy-contracts__container-list__btns">
            <button class="new-energy-contracts__container-list__btns-cancel" (click)="cancelFormContract()">
                {{ "modal.cancel" | translate }}
            </button>
            <button 
                (click)="createFormContract()"
                class="new-energy-contracts__container-list__btns-continue"
                [disabled]="!generalFormValidation()"
                [class.disabled]="!generalFormValidation()"
                [class.enabled]="generalFormValidation()">
                {{ "modal.continue" | translate }}
            </button>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { HeaderComponent } from './header/header.component';
import { CardIndicatorComponent } from './card-indicator/card-indicator.component';
import { CardModuleComponent } from './card-module/card-module.component';
import { CardModuleControllerComponent } from './card-module-controller/card-module-controller.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { TagIconComponent } from './tag-icon/tag-icon.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomSnackbarSuccessComponent } from './custom-snackbar-success/custom-snackbar-success.component';
import { CustomSnackbarErrorComponent } from './custom-snackbar-error/custom-snackbar-error.component';
import { SnackbarComponent } from './snackbars/snackbar.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { CardIconComponent } from './card-icon/card-icon.component';
import { DialogYesOrNoComponent } from './dialog-yes-or-no/dialog-yes-or-no.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { OnlyNumbersDirective } from '../directive/only-numbers.directive';
import { InputTimeComponent } from './time-picker/input-time/input-time.component';
import { ScheduleCardComponent } from './schedule-card/schedule-card.component';

@NgModule({
  declarations: [
    HeaderComponent,
    CardIndicatorComponent,
    CardModuleComponent,
    CardModuleControllerComponent,
    ModalDialogComponent,
    TagIconComponent,
    CustomSnackbarSuccessComponent,
    CustomSnackbarErrorComponent,
    SnackbarComponent,
    ResourceNotFoundComponent,
    CardIconComponent,
    DialogYesOrNoComponent,
    LineChartComponent,
    BarChartComponent,
    TimePickerComponent,
    OnlyNumbersDirective,
    InputTimeComponent,
    ScheduleCardComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    MatExpansionModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [
    HeaderComponent,
    CardIndicatorComponent,
    CardModuleComponent,
    CardModuleControllerComponent,
    TagIconComponent,
    CustomSnackbarSuccessComponent,
    CustomSnackbarErrorComponent,
    SnackbarComponent,
    ResourceNotFoundComponent,
    CardIconComponent,
    LineChartComponent,
    BarChartComponent,
    TimePickerComponent,
    ScheduleCardComponent
  ],
})
export class ComponentsModule {}

import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { UgridOperationGranularityEnum } from 'src/app/core/models/enums/ugrid-operation-granularity.enum';
import { UgridMeasurementTypeEnum } from 'src/app/core/models/enums/ugrid-operation-measurement-type.enum';
import { UgridOperationService } from 'src/app/core/services/ugrid-services/ugrid-operation.service';
import { LineChartSetupUtil } from 'src/app/core/utils/line-chart-setup.util';

Chart.register(zoomPlugin);

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent {
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  currentDate = new Date().toLocaleDateString(this.translate.currentLang, { dateStyle: 'long' });
  lineChartCfgDataSet: ChartConfiguration<'line'>['data'] | undefined;
  lineChartOptions: ChartOptions<'line'> | undefined;
  chartSetupUtil: LineChartSetupUtil;
  loaded = false;
  operations: any;

  constructor(
    private readonly ugridOperationService: UgridOperationService,
    private translate: TranslateService,
  ) {
    this.chartSetupUtil = new LineChartSetupUtil(translate);
  }

  async ngAfterViewInit() {
    var timedifference = new Date().getTimezoneOffset(); // apply this because the query on backend use timezone to filter the data
    const intDate = moment().startOf('day').add(timedifference, 'minutes').toDate();
    const endDate = moment().endOf('day').add(timedifference, 'minutes').toDate();
    this.operations = await this.ugridOperationService.getAll(
      intDate,
      endDate,
      UgridOperationGranularityEnum.hour,
      UgridMeasurementTypeEnum.Power
    );
    this.lineChartCfgDataSet = await this.getOperationSeries();
    this.lineChartOptions = this.chartSetupUtil.getLineChartOptions();
    this.loaded = true;

    this.chartSetupUtil.appendCheckboxInput(this.lineChartCfgDataSet?.datasets);
    this.addEventInCheckbox(this.lineChartCfgDataSet?.datasets);
  }

  async getOperationSeries(): Promise<ChartConfiguration<'line'>['data']> {
    return this.chartSetupUtil.getUgridOperationSeriesDataset(
      this.operations,
      UgridOperationGranularityEnum.hour,
    );
  }

  checkboxChangeEffect(event: any): void {
    const index = event.target.value;
    if (this.chart?.chart?.isDatasetVisible(index)) {
      this.chart?.chart?.hide(index);
    } else {
      this.chart?.chart?.show(index);
    }
  }

  addEventInCheckbox(datasets: ChartDataset[]): void {
    datasets.forEach((dataset, index) => {
      const ckBox = document.getElementById(`checkbox${index}`);
      ckBox?.addEventListener('change', (e) => {
        this.checkboxChangeEffect(e);
      });
    });
  }

  resetZoom(): void {
    this.chart?.chart?.resetZoom();
  }
}

export enum SchedulePolicyStatusEnum {
  unknown = 0,
  off = 1,
  on = 2,
  standBy = 3,
  uncharge = 4,
  charge = 5,
  consuming = 6,
  injecting = 7,
  changeControlMode = 55,
}
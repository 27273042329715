<div class="schedules">
	<div class="schedules-header">
		<div class="schedules-header__text">
			<h2>{{ "controlPanel.schedules.title" | translate }}</h2>
			<p>{{ "controlPanel.schedules.info" | translate }}</p>
		</div>
		<button class="schedules-header__btn" (click)="goToCreateSchedule('/control-panel/create-schedule')">
			{{ "controlPanel.schedules.createSchedule" | translate }}
		</button>	
	</div>

	<div class="background">
		<div class="sub-header">
			<div class="title">
				{{ "scheduling.subTitle" | translate }}
				<div class="operation-number">{{ schedulingQuantity }} {{ "scheduling.schedules" | translate }}</div>
			</div>
			<mat-form-field appearance="outline">
				<mat-label>{{ "scheduling.filter" | translate }}</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
				<mat-icon matSuffix>search</mat-icon>
			</mat-form-field>
		</div>

		<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="outer-table">

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="column-title"> {{ "scheduling.column.name" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.moduleName}} </td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="column-title"> {{ "scheduling.column.status" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.controlMode}} </td>
			</ng-container>
			<ng-container matColumnDef="expand">
				<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
				<td mat-cell *matCellDef="let element" class="chevron-down">
					<button mat-icon-button aria-label="expand row"
						(click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
						<mat-icon *ngIf="expandedElement !== element">keyboard_arrow_right</mat-icon>
						<mat-icon *ngIf="expandedElement === element">keyboard_arrow_down</mat-icon>
					</button>
				</td>
			</ng-container>

			<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
			<ng-container matColumnDef="expandedDetail">
				<td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
					<div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
						<div class="inner-table" *ngIf="element.schedulesItems.length!==0">
							<table multiTemplateDataRows [dataSource]="element.schedulesItems" #innerTables mat-table matSort class="internal-table">
								<ng-container matColumnDef="days">
									<th mat-header-cell *matHeaderCellDef mat-sort-header style="width:212px;" class="inner-table-desc"> {{ "scheduling.rowExpand.days" | translate }} </th>
									<td mat-cell *matCellDef="let module" class="inner-table-value">
										<div class="inner-table-value">
											{{module.days}}
										</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="start_time">
									<th mat-header-cell *matHeaderCellDef mat-sort-header class="inner-table-desc"> {{ "scheduling.rowExpand.time" | translate }} </th>
									<td mat-cell *matCellDef="let module" class="inner-table-value">
										<div class="inner-table-value">
											{{module.startTime}} - {{module.endTime}}
										</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef mat-sort-header style="width:195px;" class="inner-table-desc"> {{ "scheduling.rowExpand.status" | translate }} </th>
									<td mat-cell *matCellDef="let module">
										<div class="inner-table-value">{{module.status}}</div>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="innerDisplayedColumns" [ngClass]="{highlighted:true}"></tr>
								<tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;" [ngClass]="{highlighted:true}" class="internal-row"></tr>
							</table>
						</div>
						<div *ngIf="element.schedulesItems.length===0" class="no-module">
							<div class="not-schedules">
								<app-tag-icon
									[icon]="'error'"
									[type]="'default'"
									[size]='18'>        
								</app-tag-icon>
								<div class="info">
									<h2>{{ notFoundSchedulesMessage | translate }}</h2>
									<hr>
									<p>
										<span class="support">{{ info1 | translate }}</span>
										{{ info2 | translate}}
									</p>
								</div>
							</div>							
						</div>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
			<tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
				[class.example-expanded-row]="expandedElement === element"
				(click)="expandedElement = expandedElement === element ? null : element">
			</tr>
			<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

			<ng-container matColumnDef="action">
				<th mat-header-cell  *matHeaderCellDef class="column-title"> {{ "scheduling.column.action" | translate }} </th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="element.schedulesItems.length === 0" class="no-button"> - </div>
					<div *ngIf="(element.controlMode === 'Ativo' || element.controlMode === 'Active') && element.schedulesItems.length !== 0">
						<button mat-stroked-button color="deactivate" (click)="openDialog(element.schedulesItems[0].moduleId, 1, element.moduleName)">{{ "scheduling.button.disable" | translate }}</button>
					</div>
					<div *ngIf="(element.controlMode === 'Inativo' || element.controlMode === 'Inactive') && element.schedulesItems.length !== 0">
						<button mat-stroked-button color="activate" (click)="openDialog(element.schedulesItems[0].moduleId, 2, element.moduleName)">{{ "scheduling.button.activate" | translate }}</button>
					</div>
				</td>
			</ng-container>

		</table>

		<mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
			aria-label="Items por pagina" showFirstLastButtons class="border"></mat-paginator>

	</div>
</div>
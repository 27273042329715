import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { ControlPainelComponent } from './control-painel/control-painel.component';
import { ComponentsModule } from '../shared/components/components.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardHomeComponent } from './home/dashboard-home/dashboard-home.component';
import { ControllerComponent } from './control-painel/controller/controller.component';
import { SchedulingComponent } from './control-painel/scheduling/scheduling.component';
import { ActivityFeedComponent } from './control-painel/activity-feed/activity-feed.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SigninComponent } from './auth/signin/signin.component';
import { DashboardMonitoringComponent } from './monitoring/dashboard-monitoring/dashboard-monitoring.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgChartsModule } from 'ng2-charts';
import { AuthComponent } from './auth/auth.component';
import { ActiveAccountComponent } from './auth/active-account/active-account.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { RetrieveAccountComponent } from './auth/retrieve-account/retrieve-account.component';
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './register/users/users.component';
import { EnergyResourcesComponent } from './register/energy-resources/energy-resources.component';
import { ModalDialogRegisterComponent } from './register/components/modal-dialog-register/modal-dialog-register.component';
import { NewResourceComponent } from './register/energy-resources/new-resource/new-resource.component';
import { StorageFormComponent } from './register/energy-resources/new-resource/storage-form/storage-form.component';
import { LoadFormComponent } from './register/energy-resources/new-resource/load-form/load-form.component';
import { GenerationFormComponent } from './register/energy-resources/new-resource/generation-form/generation-form.component';
import { GeneralInformationComponent } from './register/general-information/general-information.component';
import { MatCardModule } from '@angular/material/card';
import { GeneralInformationService } from '../core/services/general-information/general-information.service';
import { LocationService } from '../core/services/locations/location.service';
import { DistributionGridComponent } from './register/distribution-grid/distribution-grid.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { DashboardsComponent } from './analysis/dashboards/dashboards/dashboards.component';
import { ReportsComponent } from './analysis/reports/reports/reports.component';
import { TimeSeriesComponent } from './analysis/time-series/time-series.component';
import { EnergyContractsComponent } from './register/energy-contracts/energy-contracts.component';
import { SharedModule } from '../shared/shared.module';
import { MatChipsModule } from '@angular/material/chips';
import { NewEnergyContractsComponent } from './register/energy-contracts/new-contract/new-energy-contracts.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DashboardCreateComponent } from './analysis/dashboards/dashboard-create/dashboard-create.component';
import { DashboardService } from '../core/services/dashboards/dashboard.service';
import { MatDividerModule } from '@angular/material/divider';
import { DateAdapter, MatNativeDateModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ConstantValueComponent } from './analysis/time-series/constant-value/constant-value.component';
import { DataUploadComponent } from './analysis/time-series/data-upload/data-upload.component';
import { DragAndDropDirective } from './analysis/time-series/data-upload/drag-and-drop.directive';
import { IndicatorComponent } from './analysis/time-series/indicator/indicator.component';
import { DataCrossingComponent } from './analysis/time-series/data-crossing/data-crossing.component';
import { ModuleMonitoringComponent } from './monitoring/module-monitoring/module-monitoring.component';
import { LocaleService } from '../core/services/localeServices/locale.service';
import { CreateScheduleComponent } from './control-painel/scheduling/create-schedule/create-schedule.component';
import { ModalNewStatusComponent } from './control-painel/scheduling/modal-new-status/modal-new-status.component';
import { NewEnergyContractsFormComponent } from './register/energy-contracts/new-contract/new-energy-contracts-form/new-energy-contracts-form.component';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    HomeComponent,
    MonitoringComponent,
    ControlPainelComponent,
    AuthComponent,
    ActiveAccountComponent,
    DashboardHomeComponent,
    ControllerComponent,
    SchedulingComponent,
    ActivityFeedComponent,
    SigninComponent,
    DashboardMonitoringComponent,
    ForgotPasswordComponent,
    RetrieveAccountComponent,
    RegisterComponent,
    UsersComponent,
    EnergyResourcesComponent,
    ModalDialogRegisterComponent,
    NewResourceComponent,
    StorageFormComponent,
    LoadFormComponent,
    GenerationFormComponent,
    GeneralInformationComponent,
    DistributionGridComponent,
    AnalysisComponent,
    DashboardsComponent,
    ReportsComponent,
    TimeSeriesComponent,
    EnergyContractsComponent,
    NewEnergyContractsComponent,
    DashboardCreateComponent,
    ConstantValueComponent,
    DataUploadComponent,
    DragAndDropDirective,
    IndicatorComponent,
    DataCrossingComponent,
    ModuleMonitoringComponent,
    CreateScheduleComponent,
    ModalNewStatusComponent,
    NewEnergyContractsFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    FormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatExpansionModule,
    MatSelectModule,
    NgChartsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatAutocompleteModule,
    SharedModule,
    MatDividerModule,
    MatPseudoCheckboxModule,
    MatGridListModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    HomeComponent,
    MonitoringComponent,
    ControlPainelComponent,
    FormsModule,
    AuthComponent,
    SigninComponent,
    ActiveAccountComponent,
    ForgotPasswordComponent,
    RetrieveAccountComponent,
    GeneralInformationComponent,
    AnalysisComponent,
    NewEnergyContractsComponent,
    DashboardCreateComponent,
    ModuleMonitoringComponent
  ],
  providers: [
    GeneralInformationService,
    LocationService,
    LocaleService,
    DashboardService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMAT
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}

<div class="new-energy-contracts-form__container-register-contract-keywords">

    <div class="new-energy-contracts-form__container__header">
        <h2>{{ "register.basicInfo" | translate }}</h2>
    </div>
    <form #contractForm="ngForm"  class="new-energy-contracts-form__container-register-contract-keywords__fields" >
        <div class="new-energy-contracts-form__container-register-contract-keywords__fields-sections">
            <div>
                <mat-panel-description>
                    <label for="name" class="required" >
                        {{ "register.energyContracts.form.name" | translate }}
                    </label>
                    <img src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{nameTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip">
                </mat-panel-description>
                <input 
                    [(ngModel)]="contract.name"
                    #name="ngModel"
                    name="name"
                    type="text" 
                    class="form-control input-field"
                    required
                    maxlength="27"
                    matInput
                    [class.invalid]="name.invalid && (name.dirty || name.touched)"
                    placeholder="{{ namePlaceholder }}">
            </div>

            <div>
                <mat-panel-description>
                    <label for="validityPeriod" class="required" >
                        {{ "register.energyContracts.form.validityPeriod" | translate }}
                    </label>                
                </mat-panel-description>
                <mat-form-field>
                    <mat-label>{{ validityPeriodPlaceholder }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker" class="input-range-field-period">
                        <input matStartDate 
                            [(ngModel)]="contract.startValidityPeriod" 
                            #startValidityPeriod="ngModel"
                            name="startValidityPeriod"
                            required
                            class="input-field-period">
                        <input matEndDate 
                            [(ngModel)]="contract.endValidityPeriod"
                            #endValidityPeriod="ngModel"
                            name="endValidityPeriod"
                            required
                            class="input-field-period">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <div>
                <mat-panel-description>
                    <label for="energyCompany" >
                        {{ "register.energyContracts.form.energyCompany" | translate }}
                    </label>                
                </mat-panel-description>
                <input 
                    [(ngModel)]="contractRule.energyCompany"
                    #energyCompany="ngModel"
                    name="energyCompany"
                    type="text" 
                    readonly="readonly"
                    [class.disabled]="true"
                    class="input-field"
                    matInput>
            </div>
        </div>

        <div class="new-energy-contracts-form__container-register-contract-keywords__fields-sections">
            <div>
                <mat-panel-description>
                    <label for="description" >
                        {{ "register.energyContracts.form.description" | translate }}
                    </label>                
                </mat-panel-description>
                <textarea 
                    rows="2"
                    maxlength="200"
                    [(ngModel)]="contract.description"
                    #description="ngModel"
                    name="description"
                    type="textarea" 
                    class="input-field-description"
                    matInput
                    placeholder="{{ descriptionPlaceholder }}"></textarea>
                
                <div class="label-description-max-size">{{ "register.energyContracts.form.description_max_caracter" | translate }}</div>
            </div>
            
        </div>

        <hr>

        <div class="new-energy-contracts-form__container__header">
            <h2>{{ "register.energyContracts.contractParameters" | translate }}</h2>
        </div>


        <div class="new-energy-contracts-form__container-register-contract-keywords__fields-sections form-row">

            <div class="form-group" *ngFor="let meta of contractRule.metadata">
                <mat-panel-description>
                    <label for="{{ meta.type }}" class="required">{{ meta.name }}</label>        
                </mat-panel-description>
                <div *ngIf="meta.options?.length == 0" class="input-group">
                    <input 
                        [(ngModel)]="meta.metadataValue"
                        name="{{ meta.type }}" 
                        type="text"
                        class="input-field-group"
                        placeholder="{{ meta.placeHolder }}"
                        [required]="meta.required"
                        [value]="meta.defaultValue">
                    <div class="input-field-group-append" *ngIf="meta.hasUnity && meta.options?.length == 0" [class.invalid]="name.invalid && (name.dirty || name.touched)">
                        <span class="input-group-text"> {{ meta.typeUnity }} </span>
                    </div>
                </div>
                <div *ngIf="meta.options?.length != 0" class="select-group">
                    <mat-form-field  class="card-contract-select" appearance="outline">
                        <mat-select 
                            [(ngModel)]="meta.metadataValue" 
                            name="{{ meta.type }}" 
                            placeholder="{{ 'register.energyContracts.form.select' | translate }}" 
                            [required]="meta.required">
                            <mat-option *ngFor="let option of meta.options" [value]="option">
                                <p>{{ option }}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-error>
                    <small>{{ meta.metadataErrorMessage }}</small>
                </mat-error>
            </div>
        </div>

        <hr>

        <div class="contract-buttons">
            <button class="contract-buttons-cancel" (click)="cancelForm()">
                {{ "register.energyContracts.form.buttonCancel" | translate }}
            </button>
            <button class="contract-buttons-save" 
                [disabled]="(!contractForm.form.valid)"
                (click)="saveForm(contractForm.form.valid)"
                >
                {{ "register.energyContracts.form.buttonSave" | translate }}
            </button>
        </div>
    </form>
</div>
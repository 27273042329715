<label *ngIf="title">
	<span class="label">{{ title }}</span>
</label>
<div
	[id]="'timepicker-' + id"
	class="input-icons"
	[matMenuTriggerFor]="menu"
	[class.disabled]="!canOpen || disabled"
>
	<i class="icon"></i>
	<input
		placeholder="00:00 {{toThe | translate}} 00:00"
		type="text"
		class="input-time"
		[ngClass]="{ 'has-error': showError || (rangeError && isRange) }"
		[formControl]="ctrlTime"
	/>
</div>
<mat-menu #menu="matMenu" class="menu-time-picker dropdown-menu-right">
	<ul
		class="time-containt"
		[attr.style]="'width: ' + panelWidth + 'px;'"
		(click)="$event.stopPropagation()"
	>
		<ng-container *ngIf="!isRange">
			<app-input-time
				[time]="time"
				(changes)="onTimeChanges($event)"
				[haveSeconds]="haveSeconds"
			></app-input-time>
		</ng-container>
		<ng-container *ngIf="isRange">
			<app-input-time
				[time]="timeRange.start"
				(changes)="onRangeChanges($event, 'initial')"
				[haveSeconds]="haveSeconds"
			></app-input-time>
			<span class="to-the" style="padding: 4px">{{ "controlPanel.schedules.container.timePicker.toThe" | translate }}</span>
			<app-input-time
				[time]="timeRange.end"
				(changes)="onRangeChanges($event, 'final')"
				[haveSeconds]="haveSeconds"
			></app-input-time>
		</ng-container>
	</ul>
	<hr />
	<div class="group">
		<button type="button" class="btn cancel" (click)="clear()">
			{{ "controlPanel.schedules.container.clear" | translate }}
		</button>
		<button
			type="button"
			class="btn"
			(click)="apply()"
		>
			{{ "controlPanel.schedules.container.apply" | translate }}
		</button>
	</div>
</mat-menu>

<mat-error *ngIf="showError && !(rangeError && isRange)">{{
	error || ("global.field-required" | translate)
}}</mat-error>

<mat-error *ngIf="rangeError && isRange">
	{{ "controlPanel.schedules.container.info.infoErrorHours" | translate }}
</mat-error>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimePickerService } from 'src/app/core/services/timePickerService/time-picker.service';
import { InputTime } from 'src/app/shared/interfaces/input-time.interface';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['../time-picker.component.scss']
})
export class InputTimeComponent {
  @Output() changes: EventEmitter<InputTime> = new EventEmitter();

	@Input() haveSeconds = true;
	@Input() set time(time: string) {
		if (typeof time != 'string' || time == '') {
			this.clearTime();
			return;
		}

		this.hourCtrl.setValue(time.split(':')[0]);
		this.minuteCtrl.setValue(time.split(':')[1]);
		this.secondCtrl.setValue(time.split(':')[2] || '');
	}

	hourCtrl = new FormControl('00');
	minuteCtrl = new FormControl('00');
	secondCtrl = new FormControl('00');

	constructor(private service: TimePickerService) {}

	public up(ctrlTypeProp: string) {
		const ctrlType = ctrlTypeProp as keyof InputTimeComponent
		if ((!ctrlType || !this[ctrlType]))
			return;
		const timeCtrl = this[ctrlType] as FormControl
		ctrlType == 'hourCtrl'
			? timeCtrl.setValue(this.service.upHour(+timeCtrl.value))
			: timeCtrl.setValue(
				this.service.upMinuteAndSecond(+timeCtrl.value)
			  );

		this.emitChanges();
	}

	public down(ctrlTypeProp: string): void {
		const ctrlType = ctrlTypeProp as keyof InputTimeComponent
		const timeCtrl = this[ctrlType] as FormControl
		timeCtrl.setValue(this.service.goDown(+timeCtrl.value));
		this.emitChanges();
	}

	public changeTime(ctrlTypeProp: string): void {
		const ctrlType = ctrlTypeProp as keyof InputTimeComponent
		const timeCtrl = this[ctrlType] as FormControl
		const vl = +timeCtrl.value;
		const formatVl =
			ctrlType === 'hourCtrl'
				? this.service.changeHour(vl)
				: this.service.changeMinAndSec(vl);
				timeCtrl.setValue(formatVl);
		this.emitChanges();
	}

	clearTime() {
		this.hourCtrl = new FormControl('00');
		this.minuteCtrl = new FormControl('00');
		this.secondCtrl = new FormControl('00');
	}

	private emitChanges() {
		this.changes.emit({
			hour: this.hourCtrl.value || "00",
			minutes: this.minuteCtrl.value || "00",
			seconds: this.secondCtrl.value || "00",
		});
	}
}

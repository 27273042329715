<div class="schedule-card" 
    [style.background-color]="backgroundColor"
    [style.height]="height"
    [style.color]="color"
>
    <h2 class="schedule-card__status">
        {{ title | translate }}
        <i class="fa-regular fa-xmark" [style.color]="color"></i>
    </h2>
    <p class="schedule-card__hours" *ngIf="hideHours">
        {{ startTime }} - {{ endTime }}
    </p>
</div>
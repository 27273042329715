import { Injectable } from '@angular/core';
import { InputTime } from 'src/app/shared/interfaces/input-time.interface';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimePickerService {

  constructor() { }

  upHour(time: number): string {
		if (time >= 23) return time.toString();
		time = time + 1;
		const strTime = time < 10 ? '0' + time : time.toString();
		return strTime;
	}

	upMinuteAndSecond(time: number): string {
		if (time >= 59) return time.toString();
		time = time + 1;
		const strTime = time < 10 ? '0' + time : time.toString();
		return strTime;
	}

	goDown(time: number): string {
		if (time == 0) return '00';
		time = time - 1;
		const strTime = time < 10 ? '0' + time : time.toString();
		return strTime;
	}

	changeHour(time: number): string {
		if (time >= 24) return '00';
		const strTime = time < 10 ? '0' + time : time.toString();
		return strTime;
	}

	changeMinAndSec(time: number): string {
		if (time > 59) return '00';
		const strTime = time < 10 ? '0' + time : time.toString();
		return strTime;
	}

	formatTime(time: InputTime, haveSeconds: boolean): string {
		let timeStr = '';
		if (!haveSeconds) timeStr = time.hour + ':' + time.minutes;
		else timeStr = time.hour + ':' + time.minutes + ':' + time.seconds;
		return timeStr;
	}

	validateTimeRange(
		startTime: string,
		endTime: string,
		haveSeconds = true
	): boolean {
		const parseTime = (timeString: string): DateTime => {
			const [hours, minutes, seconds] = timeString.split(':').map(Number);
			return DateTime.utc().set({
				hour: hours,
				minute: minutes,
				second: haveSeconds ? seconds : 0,
			});
		};
		const startDateTime = parseTime(startTime);
		const endDateTime = parseTime(endTime);
		return startDateTime < endDateTime;
	}
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { EnergyContractsService } from 'src/app/core/services/energyContracts/energy-contracts.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, Observable, startWith } from 'rxjs';
import { ContractRuleRequest } from 'src/app/core/models/request/contract-rule-request.model';

@Component({
  selector: 'app-new-energy-contracts',
  templateUrl: './new-energy-contracts.component.html',
  styleUrls: ['./new-energy-contracts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NewEnergyContractsComponent {
  @ViewChild('paginator') paginator!: MatPaginator;

  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  @ViewChild('keywordInput') keywordInput?: ElementRef<HTMLInputElement>;

  selectedRow: ContractRuleRequest | undefined;
  dataSource = new MatTableDataSource();

  @Output() cancelForm = new EventEmitter<boolean>();
  @Output() formSaved = new EventEmitter<boolean>();
  columnsToDisplay = ['selected', 'name', 'tags'];
  filterRulesForm: FormGroup;
  keywordCtrl = new FormControl('');
  filteredKeywords?: Observable<string[]>;
  keywords: string[] = [];
  allKeywords: string[] = [];
  allContractRules: ContractRuleRequest[] = [];
  editContractForm: boolean = false;
  private storage: Storage;

  announcer = inject(LiveAnnouncer);

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private energyContractsService: EnergyContractsService,
  ) {
    this.filterRulesForm = this.fb.group({});
    this.storage = window.localStorage;
  }

  async ngOnInit(): Promise<void> {
    await this.getKeywords();
    await this.getAllRules();
    this.filteredKeywords = this.keywordCtrl.valueChanges.pipe(
      startWith(null),
      map((keyword: string | null) => (keyword ? this._filterByKeyword(keyword) : this.allKeywords.slice())),
    );
  }

  ngAfterViewInit() {
    this.translateMatPaginator(this.paginator);
    this.dataSource.paginator = this.paginator;
  }

  cancelFormContract(): void {
    this.allContractRules.forEach((x) => {
      x.selected = false;
    });
    this.selectedRow = undefined;
    this.cancelForm.emit(true);
  }

  newContractEvent(value: boolean): void {
    if (value)
      this.formSaved.emit(true);
    else
      this.editContractForm = false; // Cancel edit and go back
  }

  createFormContract(): void {
    if (this.selectedRow) {
      this.storage.setItem('contractRuleId', this.selectedRow.contractRuleId.toString());
      this.editContractForm = true;
    }
  }

  generalFormValidation(): boolean {
    return this.selectedRow !== undefined;
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  async getKeywords(): Promise<string[]> {
    return await this.energyContractsService.getAllKeywords().then((res) => {
      this.allKeywords = res;
      return this.allKeywords;
    });
  }

  async getAllRules(): Promise<ContractRuleRequest[]> {
    return await this.energyContractsService.getAllContractRules().then((res) => {
      this.allContractRules = res;
      this.allContractRules.forEach((x) => {
        x.tagsFormated = x.tags.join(', ');
      });

      this.dataSource.data = this.allContractRules;
      return this.allContractRules;
    });
  }

  clearKeywords() {
    this.keywords = [];

    this.filterContractRules(this.keywords);
    this.selectedRow = undefined;
  }

  async filterContractRules(keywords: string[]) {
    this.allContractRules.forEach((x) => {
      x.selected = false;
    });

    if (keywords.length === 0) {
      this.dataSource.data = this.allContractRules;
    } else {
      const result = this.allContractRules.filter((x) => {
        return x.tags.some((r) => keywords.includes(r));
      });
      this.dataSource.data = result;
    }
  }

  addKeyword(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value && this.allKeywords.indexOf(value) > -1) {
      this.keywords.push(value);
      this.filterContractRules(this.keywords);
      this.selectedRow = undefined;
    }

    // Clear the input value
    event.chipInput!.clear();

    this.keywordCtrl.setValue(null);
  }

  removeKeyword(keyword: string): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);

      this.announcer.announce(`Removed ${keyword}`);
      this.filterContractRules(this.keywords);
      this.selectedRow = undefined;
    }
  }

  selectedKeywordEvent(event: MatAutocompleteSelectedEvent): void {
    if (this.keywords.indexOf(event.option.viewValue) === -1) {
      this.keywords.push(event.option.viewValue);
      this.filterContractRules(this.keywords);
      this.selectedRow = undefined;
    }

    this.keywordInput!.nativeElement.value = '';
    this.keywordCtrl.setValue(null);
  }

  private _filterByKeyword(keyword: string): string[] {
    if (keyword?.length > 0) {
      const filterValue = keyword.toLowerCase();
      return this.allKeywords.filter((keyword) => keyword.toLowerCase().includes(filterValue));
    }
    return [];
  }

  selectedRowRuleEvent(selectedRule: ContractRuleRequest, event: any): void {
    this.allContractRules.forEach((x) => {
      x.selected = false;
    });

    if (event?.checked) {
      selectedRule.selected = true;
      this.selectedRow = selectedRule;
    } else {
      this.selectedRow = undefined;
      selectedRule.selected = false;
    }
  }
}

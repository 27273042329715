export enum ContractMetadataTypeEnum {
    Text = 'Text',
    Number = 'Number',
    Time = 'Time',
    Date = 'Date',
    DateTime = 'DateTime',
    CurrencyNumber = 'CurrencyNumber',
    PercentNumber = 'PercentNumber',
    PeakDemandNumber = 'PeakDemandNumber',
    OffPeakDemandNumber = 'OffPeakDemandNumber',
    StartPeakTime = 'StartPeakTime',
    EndPeakTime = 'EndPeakTime',
    ContractEnvironment = 'ContractEnvironment',
    EnergyFee = 'EnergyFee',
    EnergyFeePeak = 'EnergyFeePeak',
    EnergyFeeOffPeak = 'EnergyFeeOffPeak',
    EnergyTariff = 'EnergyTariff',
    TUSD = 'TUSD',
    PIS = 'PIS',
    COFINS = 'COFINS'
  }